import { useForm, ValidationError } from "@formspree/react";
import React, { FormEvent } from "react";
import useBoolean from "../hooks/useBoolean";
import Button from "./UI/Button";
import Input from "./UI/Input";
import Switch from "./UI/Switch";

const ContactForm: React.FC = () => {
  const [state, handleSubmit] = useForm("mqkodwed");
  const [isArgee, setAgree] = useBoolean();

  const onSubmit = (event: any) => {
    if (!isArgee) {
      event.preventDefault();
      return;
    };

    handleSubmit(event);
  }

  return (
    <div>
      <form className="text-center" onSubmit={onSubmit}>
        <label className="text-sm text-left text-grey-400 block mb-2">
          Question Type
          <span className="text-error"> *</span>
        </label>
        <div className="chevron-down-right mb-4 lg:mb-[33px]">
          <select
            name="help"
            className="relative px-6 h-14 rounded-[50px] w-full text-base text-black-light border border-grey-100 font-semibold bg-white placeholder-shown:font-normal focus:outline-none focus:border-black-light appearance-none chevron-down-right"
          >
            <option>Question Type</option>
            <option value="1">General</option>
            <option value="2">Arrange A Trial</option>
            <option value="3">Book A Free Whiteboarding Session</option>
            <option value="4">Content Feedback</option>
            <option value="5">Partnerships</option>
          </select>
        </div>
        <ValidationError prefix="Help" field="help" errors={state.errors} />
        <div className="flex lg:flex-row flex-col gap-6 items-center mb-4 lg:mb-[33px]">
          <Input
            type="text"
            inputProps={{ name: "name", required: true }}
            placeholder="Your Name"
            label="Your Name"
            className="!w-full"
            isRequired
          />
          <ValidationError
            prefix="Your Name"
            field="name"
            errors={state.errors}
          />

        <Input
            type="text"
            inputProps={{ name: "company", required: true }}
            placeholder="Your Company"
            label="Your Company"
            className="!w-full"
            isRequired
          />
          <ValidationError
            prefix="Your Company"
            field="company"
            errors={state.errors}
          />


          <Input
            type="email"
            placeholder="Type your email"
            className="!w-full"
            inputProps={{ name: "email", required: true }}
            label="Your Email"
            isRequired
          />
        </div>
        <ValidationError
          prefix="Email Address"
          field="email"
          errors={state.errors}
        />
        <label className="text-sm text-grey-400 text-left block mb-2">
          Comment
          <span className="text-error"> *</span>
        </label>
        <textarea
          className="w-full h-[92px] px-6 py-4 mb-4 lg:mb-[33px] bg-white border rounded-2xl ring-0 focus:border-black-light outline-none font-semibold placeholder-grey-400 placeholder-shown:font-normal text-black-light"
          name="message"
          cols={30}
          rows={30}
          placeholder="Type your comment"
          required
        />
        <ValidationError
          prefix="Message"
          field="message"
          errors={state.errors}
        />
        <div className="flex lg:items-center lg:flex-row gap-8 lg:gap-4 flex-col lg:justify-between">
          <div className="flex items-center gap-4">
            <Switch
              isEnabled={isArgee}
              onChange={setAgree.toggle}
              color="red"
              size="small"
            />
            <span className="text-sm">
              Accept Terms & Conditions Of Use
            </span>
          </div>
          <Button
            actionType="submit"
            color="red"
            label="Send message"
            disabled={!isArgee}
          />
        </div>
      </form>
      {state.succeeded ? (
        <p className="text-xl text-center mb-4 lg:mt-[26px]">
          Thanks for submitting! We will contact with you soon.
        </p>
      ) : (
        ""
      )}
    </div>
  );
};

export default ContactForm;
