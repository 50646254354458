import React from "react";

import ContactForm from "../components/ContactForm";
import DefaultLayout from "../components/layouts/DefaultLayout";
import SEO from "../components/layouts/SEO";
import Hero from "../components/StaticPage/Hero";
import { ReactComponent as FacebookIcon } from "../images/icons/facebook.svg";
import { ReactComponent as TwitterIcon } from "../images/icons/twitter.svg";
import { ReactComponent as YoutubeIcon } from "../images/icons/youtube.svg";
import { ReactComponent as LinkedinIcon } from "../images/icons/linkedin.svg";
import { StaticImage } from "gatsby-plugin-image";


const Contact: React.FC = () => {
  return (
    <DefaultLayout>
      <SEO
        title="Contact Us | Ensemble"
        url="https://ensembleai.io/contact"
        desc="Contact us at Ensemble"
        ogImage="https://ensembleai.io/assets/img/home.png"
      />
      <Hero
        title="Contact Us"
        backgroundColor={["#40b7de", "#1976e0"]}
        description="Get In Touch With The Ensemble Team"
      />
      <div className="block-contact bg-brown-500 py-10 lg:pt-14 pb-[6rem]">
        <div className="max-w-[1188px] mx-auto px-6">
          <div className="md:max-w-lg mx-auto text-center mb-7 text-white">
            <h2 className="mb-4 text-xl font-bold">
              Lets Start The Conversation
            </h2>
            <p className="text-sm">
              Please reach out using the contact form or via one of the routes
              below. A member of the team will respond within 24 hours.
            </p>
          </div>
          <div className="flex flex-wrap justify-evenly flex-col md:flex-row md:max-w-4xl mx-auto">

            <div className="lg:w-1/3 md:w-1/2 md:px-5 py-4 lg:py-3 text-center flex flex-col gap-4">
              <div className="border border-yellow-500 flex items-center justify-center mx-auto rounded-full text-yellow-500 w-[60px] h-[60px]">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 4.5C16.5 3.675 15.825 3 15 3H3C2.175 3 1.5 3.675 1.5 4.5M16.5 4.5V13.5C16.5 14.325 15.825 15 15 15H3C2.175 15 1.5 14.325 1.5 13.5V4.5M16.5 4.5L9 9.75L1.5 4.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <p className="mb-2 text-grey-400 text-sm">Email Address</p>
                <a
                  href="mailto:hello@ensembleanalytics.io"
                  className="text-white font-semibold"
                >
                  hello@ensembleai.io<br/>
                  careers@ensembleai.io
                </a>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/2 px-5 py-4 lg:py-3 text-center flex flex-col gap-4">
              <div className="border border-yellow-500 flex items-center justify-center mx-auto rounded-full text-yellow-500 w-[60px] h-[60px]">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g clipPath="url(#clip0_2525_14303)">
                    <path
                      d="M15.75 7.5C15.75 12.75 9 17.25 9 17.25C9 17.25 2.25 12.75 2.25 7.5C2.25 5.70979 2.96116 3.9929 4.22703 2.72703C5.4929 1.46116 7.20979 0.75 9 0.75C10.7902 0.75 12.5071 1.46116 13.773 2.72703C15.0388 3.9929 15.75 5.70979 15.75 7.5Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                    <path
                      d="M9 9.75C10.2426 9.75 11.25 8.74264 11.25 7.5C11.25 6.25736 10.2426 5.25 9 5.25C7.75736 5.25 6.75 6.25736 6.75 7.5C6.75 8.74264 7.75736 9.75 9 9.75Z"
                      stroke="currentColor"
                      strokeWidth="2"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                    />
                  </g>
                </svg>
              </div>
              <div>
                <p className="mb-2 text-grey-400 text-sm">Community</p>
                <p className="text-white font-semibold">
                  <a href="https://discord.gg/hguMJkk9fX">Discord Community</a>
                </p>
              </div>
            </div>

            <div className="lg:w-1/3 md:w-1/2 px-5 py-4 lg:py-3 text-center flex flex-col gap-4">
              <div className="border border-yellow-500 flex items-center justify-center mx-auto rounded-full text-yellow-500 w-[60px] h-[60px]">
                <svg
                  width="18"
                  height="18"
                  viewBox="0 0 18 18"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M16.5 4.5C16.5 3.675 15.825 3 15 3H3C2.175 3 1.5 3.675 1.5 4.5M16.5 4.5V13.5C16.5 14.325 15.825 15 15 15H3C2.175 15 1.5 14.325 1.5 13.5V4.5M16.5 4.5L9 9.75L1.5 4.5"
                    stroke="currentColor"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              </div>
              <div>
                <p className="mb-[15px] text-grey-400 text-sm">Follow Us</p>
                <div className="flex gap-4 items-center justify-center mx-auto">
                  <a
                    className="w-11 h-11 border-2 border-white rounded-full flex items-center justify-center transition-colors text-white hover:text-black-light hover:bg-white"
                    href="#"
                    target="_blank"
                    rel="noreferrer noopener"
                  >
                    <FacebookIcon />
                  </a>
                  <a
                    className="w-11 h-11 border-2 border-white rounded-full flex items-center justify-center transition-colors text-white hover:text-black-light hover:bg-white"
                    href="https://twitter.com/we_are_ensemble"
                    target="_blank"
                    rel="noreferrer noopener"
                  > 
                    <TwitterIcon />
                  </a>
                  <a
                    className="w-11 h-11 border-2 border-white rounded-full flex items-center justify-center transition-colors text-white hover:text-black-light hover:bg-white"
                    target="_blank"
                    href="https://www.youtube.com/@ensembleanalytics"
                    rel="noreferrer noopener"
                  >
                    <YoutubeIcon />
                  </a>

                  <a
                    className="w-11 h-11 border-2 border-white rounded-full flex items-center justify-center transition-colors text-white hover:text-black-light hover:bg-white"
                    target="_blank"
                    href="https://www.linkedin.com/company/ensembleanalyticsdxb"
                    rel="noreferrer noopener"
                  >
                    <LinkedinIcon />
                  </a>

                </div>
              </div>
            </div>
          </div>
        </div>

        <div className={`w-[90%] max-w-[1188px] mx-auto md:mb-[-23rem] mb-[-11rem] mt-[5rem] md:mt-[5rem] text-center`}>
          <h2 className="text-xl text-white font-semibold">We Are A Globally Distributed Team Serving Global Clients</h2>
          <p className="text-sm text-white max-w-[35rem] mx-auto mt-4 mb-9">Headquartered in London, we are a distributed team who work with clients across industries and regions.</p>

          <div className="
            w-full pt-14 px-8 lg:px-10 flex flex-col-reverse lg:flex-row gap-6 justify-between
            rounded-2xl bg-gradient-to-r from-red-600 to-pink-600 relative z-[2]
          ">
            <StaticImage
              src="../images/contact/globe.png"
              alt="Globe"
              className="!mt-[-1.5rem]"
            />
          </div>
        </div>

      </div>

      <div className="contact-form-block pb-20 pb-[8rem] !pt-[10rem] md:!pt-[23rem]">
        <div className="max-w-[744px] mx-auto px-6 lg:px-10">
          <h2 className="text-center mb-4 text-xl font-bold">
            Contact Form
          </h2>
          <ContactForm />
        </div>
      </div>
    </DefaultLayout>
  );
};

export default Contact;
